<template>
  <div class="pb-20">
    <div class="mb-10">
      <h2 class="text-2xl font-bold">Cash Configurations</h2>
    </div>
		<div class="border border-solid border-blue-200 pt-4">
		<datatable
            :ajax="true"
            :ajax-pagination="true"
            :url="`${this.$baseurl}/admin/personal/cash/configuration`"
            :columns="configurations.columns"
            :query="configurations.query"
            :on-click="click"
            sort="configurations.created_at"
            order="asc"
            ref="table"
		>
      </datatable>
    </div>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px"
      ref="configurationModal"
    >
      <h3 class="font-bold mb-2 text-xl">Update Configuration</h3>
      <p class="mb-10 text-gray-600">
        Update {{ this.configurationName }}
      </p>
      <form
        @submit.prevent="updateConfiguration"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="configurationForm.data.value.value"
          :form="configurationForm"
          name="value"
          type="string"
        >
          Enter the value
        </form-group>
      
        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="clearForm"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="configurationForm.loading"
          >
            <sm-loader-white v-if="configurationForm.loading" />
            <span v-else>Update</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
        configurationForm: this.$options.basicForm([
            { name: 'value', rules: 'required' }
        ]),
        configurationName: null,
        configurationKey: null,
        configurations: this.$options.resource([], {
        query: '',
        columns: [
          {
            name: "name",
            th: "Name",
            render: ({ title }) => title,
          },
          {
            name: "value",
            th: "Value",
            render: ({ value }) => value,
          },
        ],
      }),
    };
  },

  methods: {
    click(configuration) {
      if (configuration) {
          this.configurationName = configuration.title;
          this.configurationKey = configuration.name;
          this.$refs.configurationModal.open();
      }
    },
    clearForm() {
        this.configurationForm = this.resetForm(this.configurationForm);
        this.$refs.configurationModal.close();
    },
    async updateConfiguration() {
      if (!this.validateForm(this.configurationForm)) return;
      this.configurationForm.loading = true;
      this.configurationForm.error = null;
      await this.$put({
        url:`${this.$baseurl}/admin/personal/cash/configuration/update`,
        headers: this.headers,
        data: {
          key: this.configurationKey,
          value: this.configurationForm.data.value.value,
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: `${this.configurationName} Updated successfully.`,
            button: 'Okay'
          });
          
          this.$emit('success');
          this.reloadTable();
          this.clearForm();
        },
        
        error: error => {
          this.$error({
            title: 'Operation not Successful',
            body: error.response.data.message,
            button: 'Okay'
          });
          this.configurationForm.error = error.response.data.message || 'unable to process request at the moment';
        }
      });
      this.configurationForm.loading = false;
    },
    async reloadTable() {
      this.$refs.table?.loadAjaxData();
    },
  },
};
</script>
